<template>
  <div class="main">
    <div class="content-box">
      <div class="div-ul">
        <div class="div-title">关于我们</div>
        <div class="div-item">公司简介</div>
        <div class="div-item">加入我们</div>
      </div>
      <div class="div-ul">
        <div class="div-title">帮助中心</div>
        <div class="div-item" @click="toWeb(1)">用户协议</div>
       
      </div>
      <div class="div-ul">
        <div class="div-title">联系我们</div>
        <div class="div-item">官方微信</div>
      </div>
      <div class="div-ul">
        <div class="div-title">友情链接</div>
        <div class="div-item" @click="toWeb(2)">阳光高考</div>
        <div v-if=" province == '10300000000007'" class="div-item" @click="official()">辽宁考试之窗</div>
        <div v-if=" province == '10300000000006'" class="div-item" @click="official()">内蒙招考信息网</div>
        <div v-if=" province == '10300000000009'" class="div-item" @click="official()">黑龙江招考信息港</div>
        <div v-if=" province == '10300000000008'" class="div-item" @click="official()">吉林教育考试院</div>
        <div v-if=" province == '10300000000005'" class="div-item" @click="official()">山西招生考试网</div>
      </div>
      <div class="div-ul1">
        <div class="div-title">关注我们</div>
        <div class="div-item1">
          <img src="@/assets/images/homeView/wx.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="footer"> 
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沈阳信畅天科技有限公司 ®版权所有 &nbsp;<span>辽ICP备2023004924号-2 </span> &nbsp;&nbsp; ©2014-2024</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFooter",
  data() {
    return {
      province: JSON.parse(localStorage.getItem("province"))?.id || "10300000000007", // 辽宁默认
    };
  },
  methods: {
    toWeb(data) {
      if (data == 1) {
        window.open("/userAgreement", "_blank");
      }
      if (data == 2) {
        window.open("https://gaokao.chsi.com.cn/", "_blank");
      }
    },
    official() {
      if (this.province == "10300000000007") {
        window.open("https://www.lnzsks.com/ptgk.html", "_blank");
      }
      else if (this.province == "10300000000006") {
        window.open("https://www.nm.zsks.cn/kszs/ptgk/", "_blank");
      }
      else if (this.province == "10300000000009") {
        window.open("https://www.lzk.hl.cn/", "_blank");
      }
      else if (this.province == "10300000000008") {
        window.open("http://www.jleea.com.cn/ptgxzs/", "_blank");
      }
      else if (this.province == "10300000000005") {
        window.open("http://www.sxkszx.cn/news/ptgk/index.html", "_blank");
      }
    },
  },
};
</script>

<style scoped lang="scss">
//引入公共样式表
@import "@/assets/style/businessTheme.scss";
.main{
  width: 100%;
  .content-box {
  width: 100%;
  height: 243px;
  background-color: #303037;
  display: flex;
  justify-content: center;
  color:hsla(0, 0%, 100%, .5);
  font-weight: 100;
  .div-ul {
    margin-top: 26px;
    width: 130px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: flex-start;
    margin-right: 100px;

    // color: #FFFFFF;
    .div-title {
      font-size: 20px;
      margin-bottom: 36px;
    }
    
    .div-item {
      margin-bottom: 12px;
      font-size: 16px;
    }
    .div-item:hover {
      cursor: pointer;
      color: #fff;
    }
  }
  .div-ul1 {
    width: 165px;
    margin-right: 100px;
    margin-top: 26px;
    // color: #FFFFFF;
    .div-title {
      font-size: 20px;
      margin-bottom: 16px;
    }
    .div-item1{
      width: 150px;
      height: 150px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .div-item {
      font-size: 16px;
    }
    .div-item:hover {
      color: #CC1D7A;
    }
  }
}
  .footer{
    width: 100%;
    height: 30px;
    background-color: #383840;
    line-height: 30px;
    text-align: center;
    a {
      color:hsla(0, 0%, 100%, .5);
      text-decoration: none;
      font-size: 14px;
      span:hover{
        color: #CC1D7A;
      }
    }
  }
}
</style>