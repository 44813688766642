import { render, staticRenderFns } from "./ArithmeticFile.vue?vue&type=template&id=70ae3af6&scoped=true"
import script from "./ArithmeticFile.vue?vue&type=script&lang=js"
export * from "./ArithmeticFile.vue?vue&type=script&lang=js"
import style0 from "./ArithmeticFile.vue?vue&type=style&index=0&id=70ae3af6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70ae3af6",
  null
  
)

export default component.exports