var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"arithmetic"},[_c('div',{staticClass:"title"},[_vm._v("计算投档综合分")]),_c('div',{staticClass:"table"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"100px","label-position":"left"}},[_c('el-row',{attrs:{"justify":"space-between"}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"省份"}},[_c('el-select',{attrs:{"placeholder":"请选择省份","popper-append-to-body":false},model:{value:(_vm.form.region),callback:function ($$v) {_vm.$set(_vm.form, "region", $$v)},expression:"form.region"}},_vm._l((_vm.regionSel),function(item){return _c('el-option',{key:item.recordId,attrs:{"label":item.abbrName,"value":item.recordId}})}),1)],1)],1),_c('el-col',{staticClass:"form_item_right",attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"专业类别"}},[_c('el-cascader',{attrs:{"options":_vm.professionSel,"props":{
                      expandTrigger: 'hover',
                      value: 'recordId',
                      label: 'artName',
                      children: 'children',
                      checkStrictly: false,
                      emitPath: true,
                     }},model:{value:(_vm.form.profession),callback:function ($$v) {_vm.$set(_vm.form, "profession", $$v)},expression:"form.profession"}})],1)],1)],1),_c('el-row',{attrs:{"justify":"space-between"}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"文化分"}},[_c('el-input',{attrs:{"type":"number"},on:{"blur":function($event){return _vm.verify(1)}},model:{value:(_vm.form.culturalScore),callback:function ($$v) {_vm.$set(_vm.form, "culturalScore", $$v)},expression:"form.culturalScore"}},[_c('div',{staticClass:"points",attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("分")])])],1)],1),_c('el-col',{staticClass:"form_item_right",attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"专业分"}},[_c('el-input',{attrs:{"type":"number"},on:{"blur":function($event){return _vm.verify(2)}},model:{value:(_vm.form.professionalScore),callback:function ($$v) {_vm.$set(_vm.form, "professionalScore", $$v)},expression:"form.professionalScore"}},[_c('div',{staticClass:"points",attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("分")])])],1)],1)],1),_c('el-button',{on:{"click":_vm.arithmeticForm}},[_vm._v("开始计算")])],1)],1),(_vm.dataInfo.comScore>0)?_c('div',{staticClass:"castAFile"},[_c('div',{staticClass:"castAFile_grade"},[_vm._v(" 投档综合分: "+_vm._s(_vm.dataInfo.comScore)+" ")]),_c('div',{staticClass:"castAFile_formula"},[_vm._v(" 综合分计算公式："+_vm._s(_vm.dataInfo.examRule)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }