<template>
  <div class="container">
    <div class="centent">
      <div class="title">
        <div class="title-top">
          <div class="left">
            <div v-if="wishingedit != recordId">
              {{ wishName }}
            </div>
            <input
              v-else
              v-model="wishingeditname"
              style="
                outline: none;
                padding-left: 10px;
                width: 150px;
                height: 30px;
                border: 1px #cc1d7a solid;
                border-radius: 5px;
                margin-right: 10px;
              "
            />
            <i
              v-if="wishingedit != recordId"
              @click="toEdit(recordId)"
              style="cursor: pointer; margin-left: 30px"
              class="el-icon-edit-outline"
            ></i>
            <div
              style="
                width: 50px;
                height: 25px;
                border: 1px #cc1d7a solid;
                font-size: 14px;
                line-height: 25px;
                text-align: center;
                margin-left: 30px;
                border-radius: 5px;
              "
              v-else
            >
              <span style="margin: 0 auto" @click="editwishing(recordId)"
                >保存</span
              >
            </div>
          </div>
          <div class="right">
            <el-row>
              <el-button
                :class="checked == 'data' ? 'button-data' : 'button-data-data'"
                @click="check('data')"
              >
                全部{{ this.dataList.length }}
              </el-button>
              <el-button
                :class="checked == 'r001' ? 'button-r001' : 'button-r001-r001'"
                @click="check('r001')"
              >
                难录取{{ this.r001List.length }}
              </el-button>
              <el-button
                :class="checked == 'r002' ? 'button-r002' : 'button-r002-r002'"
                @click="check('r002')"
              >
                可冲刺{{ this.r002List.length }}
              </el-button>
              <el-button
                :class="checked == 'r003' ? 'button-r003' : 'button-r003-r003'"
                @click="check('r003')"
              >
                稳妥{{ this.r003List.length }}
              </el-button>
              <el-button
                :class="checked == 'r004' ? 'button-r004' : 'button-r004-r004'"
                @click="check('r004')"
              >
                保底{{ this.r004List.length }}
              </el-button>
            </el-row>
          </div>
        </div>
        <div class="title-main">
          <div class="left">
            我的成绩：{{ comScore }}分&nbsp;{{ firstMajor }}
          </div>
          <div class="right">
            <el-button
              :disabled="disable"
              class="main-btn"
              @click="saveOrModify()"
              >{{ drag ? "修改" : "保存" }}</el-button
            >
            <!-- <el-button class="main-btn" @click=print()>打印</el-button> -->
            <el-button class="main-btn" @click="downloadExcel">下载</el-button>
          </div>
        </div>
        <div class="sort-group" v-if="!drag">
          <el-row>
            <el-button
              type="primary"
              @click="top985()"
              :style="{
                background: is985 == false ? '#CC1D7A' : '#fff',
                color: is985 == false ? '#fff' : '#CC1D7A',
                border: is985 == false ? '0' : '1px solid #CC1D7A',
              }"
            >
              985/211优先
            </el-button>
            <el-button
              type="primary"
              @click="topPublic()"
              :style="{
                background: isPublic == false ? '#CC1D7A' : '#fff',
                color: isPublic == false ? '#fff' : '#CC1D7A',
                border: isPublic == false ? '0' : '1px solid #CC1D7A',
              }"
            >
              公办优先
            </el-button>
            <el-button
              @click="sortChange()"
              :style="{
                background: sort == 1 ? '#CC1D7A' : '#fff',
                color: sort == 1 ? '#fff' : '#CC1D7A',
                border: sort == 1 ? '0' : '1px solid #CC1D7A',
              }"
              type="primary"
            >
              分数排序
              <i
                :class="`el-icon-${
                  sort === 1
                    ? 'sort'
                    : sort === 2
                    ? 'sort-up'
                    : sort === 3
                    ? 'sort-down'
                    : 'sort'
                }`"
              ></i>
            </el-button>
          </el-row>
        </div>

        <div class="title-bottom" v-if="wishModeCode == 'wish_one'">
          <div style="width: 30px">排序</div>
          <div style="width: 90px; margin-left: 40px">录取概率</div>
          <div style="width: 200px; margin-left: 30px">招生院校</div>
          <div style="width: 150px; text-align: center; margin-left: 20px">
            招生专业
          </div>
          <div style="width: 105px; margin-left: 40px">
            {{ this.titleYear[0] + 1 }}年招生计划
          </div>
          <div style="width: 100px; text-align: center; margin-left: 25px">
            历年数据批次线<br />
            (文化/专业)
          </div>
          <div style="width: 35px; margin-left: 50px">
            {{ computeYear }} {{ this.titleYear[0] }}
          </div>
          <div style="width: 35px; margin-left: 80px">
            {{ this.titleYear[1] }}
          </div>
          <div style="width: 35px; margin-left: 60px">
            {{ this.titleYear[2] }}
          </div>
          <div></div>
        </div>
      </div>
      <div class="main" ref="pdfContent">
        <ul
          v-if="wishModeCode == 'wish_one'"
          infinite-scroll-distance="1px"
          :infinite-scroll-disabled="disable"
          style="
            overflow: auto;
            max-height: 800px;
            width: 1220px;
            margin: 0 auto;
          "
        >
          <Container
            @drop="onDrop"
            :non-drag-area-selector="dragElement"
            id="pdfContent"
          >
            <Draggable
              v-for="(item, index) in this.dataList"
              :key="item.indexId"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <li
                class="ul-li unDrag"
                v-show="
                  checked === 'data' || checked === item.probabilityTypeCode
                "
              >
                <div
                  style="
                    height: 100px;
                    width: 1200px;
                    margin-left: 10px;
                    display: flex;
                    user-select: none;
                    position: relative;
                  "
                >
                  <div style="width: 20px; min-width: 20px; text-align: center">
                    {{ index + 1 }}
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      margin-left: 30px;
                      align-items: center;
                      width: 90px;
                    "
                  >
                    <div
                      :style="{
                        color:
                          item.probabilityTypeCode == 'r001'
                            ? '#f05138'
                            : item.probabilityTypeCode == 'r002'
                            ? '#f59e49'
                            : item.probabilityTypeCode == 'r003'
                            ? '#2ccae1'
                            : item.probabilityTypeCode == 'r004'
                            ? '#28E197'
                            : '#576ae9',
                      }"
                      style="font-size: 24px; margin-bottom: 10px"
                    >
                      {{ item.probability }}%
                    </div>
                    <div
                      :style="{
                        color:
                          item.probabilityTypeCode == 'r001'
                            ? '#f05138'
                            : item.probabilityTypeCode == 'r002'
                            ? '#f59e49'
                            : item.probabilityTypeCode == 'r003'
                            ? '#2ccae1'
                            : item.probabilityTypeCode == 'r004'
                            ? '#28E197'
                            : '#576ae9',
                      }"
                    >
                      {{ item.probabilityTypeName }}
                    </div>
                  </div>
                  <div style="margin-left: 35px; min-width: 200px">
                    <div style="display: flex; align-items: center">
                      <div
                        style="
                          font-size: 16px;
                          font-weight: 500;
                          white-space: normal;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          word-wrap: break-word;
                          cursor: pointer;
                          width: 200px;
                        "
                        :title="item.schoolName"
                      >
                        {{ item.schoolName }}
                      </div>
                    </div>
                    <div
                      style="
                        font-size: 13px;
                        margin-top: 5px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 130px;
                      "
                      :title="item.provinceName + item.regionName"
                    >
                      {{ item.provinceName }} {{ item.regionName }}
                    </div>
                    <div
                      style="color: #cc1d7a; margin-top: 5px; font-size: 13px"
                    >
                      {{ item.schoolType }}&nbsp;&nbsp;/硕士点({{
                        item.masterFst + item.masterSec
                      }})&nbsp;&nbsp;/博士点({{
                        item.doctorFst + item.doctorSec
                      }})
                    </div>
                    <div style="margin-top: 5px; font-size: 13px">
                      院校代码&nbsp;&nbsp;{{
                        item.schoolCode
                      }}&nbsp;&nbsp;&nbsp;&nbsp;评级：6级
                    </div>
                  </div>
                  <div
                    style="
                      width: 200px;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      font-size: 13px;
                    "
                  >
                    <div
                      style="
                        margin-top: 8px;
                        white-space: normal;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        text-align: center;
                        cursor: pointer;
                      "
                      :title="item.schoolMajorName"
                    >
                      {{ item.schoolMajorName }}
                    </div>
                    <div
                      style="
                        margin-top: 20px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                        text-align: center;
                      "
                      :title="`专业代码:${item.schoolMajorCode}`"
                    >
                      专业代码:{{ item.schoolMajorCode }}
                    </div>
                    <div
                      style="
                        margin-top: 15px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                        text-align: center;
                        cursor: pointer;
                        color: #cc1d7a;
                      "
                      :title="
                        item.majorRanking
                          ? `${item.majorRanking}${
                              item.isImportant ? '/国家一流专业' : ''
                            }`
                          : '无'
                      "
                    >
                      评级：{{ item.majorRanking ? item.majorRanking : "无"
                      }}{{ item.isImportant ? "/国家一流专业" : "" }}
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      width: 100px;
                      position: absolute;
                      top: 0px;
                      right: 510px;
                    "
                  >
                    <div style="margin-top: 5px; font-size: 20px">
                      {{ item.planNums }}人
                    </div>
                    <div style="margin-top: 15px; font-size: 14px">
                      {{ item.collegeYear }}/￥{{ item.collegeFee }}
                    </div>
                  </div>
                  <div
                    style="
                      margin-left: 105px;
                      font-size: 14px;
                      position: absolute;
                      top: 0px;
                      right: 370px;
                      text-align: center;
                    "
                  >
                    <div>计划数</div>
                    <div style="margin-top: 20px">录取分数(折算/<span style="color: red;">原始</span>)</div>
                    <div style="margin-top: 20px">投档位次</div>
                  </div>
                  <div
                    v-for="year in item.yearlyScores"
                    :key="year.yearName"
                    :style="computeStyle(year.yearName)"
                    style="display: flex;flex-direction: column;
    align-items: center;"
                  >
                    <div style="width: 80px">
                      {{
                        year.yearPlanNbr
                          ? year.yearPlanNbr
                          : year.yearPlanNbr == "0"
                          ? "0"
                          : "-"
                      }}
                    </div>
                    <div style="margin-top: 20px; text-align: center;width: 80px">
                      {{
                        year.yearAdmScore
                          ? year.yearAdmScore
                          : year.yearAdmScore == "0"
                          ? "0"
                          : "-"
                      }}/<span style="color: red;">{{
                      year.oldYearAdmScore
                        ? year.oldYearAdmScore
                        : year.oldYearAdmScore == "0"
                        ? "0"
                        : "-"
                    }}</span>
                    </div>
                    <div style="margin-top: 20px; text-align: center;width: 80px">
                      {{
                        year.yearRank
                          ? year.yearRank
                          : year.yearRank == "0"
                          ? "0"
                          : "-"
                      }}
                    </div>
                  </div>
                  <div
                    v-if="drag == false"
                    style="
                      display: flex;
                      align-items: center;
                      margin-left: 20px;
                      position: absolute;
                      top: 40px;
                      right: 5px;
                    "
                  >
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        margin-right: 20px;
                      "
                    ></div>
                    <div>
                      <i
                        class="el-icon-rank"
                        style="color: #bfbfbf; cursor: move; margin-right: 10px"
                      ></i>
                      <el-popconfirm
                        title="确定删除该条数据吗？"
                        @confirm="deleteItem(item.indexId)"
                      >
                        <template #reference>
                          <i
                            class="el-icon-delete"
                            style="color: #bfbfbf; cursor: pointer"
                          ></i>
                        </template>
                      </el-popconfirm>
                    </div>
                  </div>
                </div>
              </li>
            </Draggable>
          </Container>
        </ul>
        <ul
          v-if="wishModeCode == 'wish_team'"
          infinite-scroll-distance="1px"
          :infinite-scroll-disabled="disable"
          style="
            overflow: auto;
            max-height: 800px;
            width: 1220px;
            margin: 0 auto;
          "
        >
          <Container
            @drop="onDrop"
            id="pdfContent"
            :non-drag-area-selector="dragElement"
          >
            <Draggable
              v-for="(item, index) in this.dataList"
              :key="item.groupId"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <div
                v-show="
                  checked === 'data' || checked === item.probabilityTypeCode
                "
                style="
                  user-select: none;
                  position: relative;
                  width: 1220px;
                  margin-left: 30px;
                  padding: 20px;
                  position: relative;
                  border-top: 1px dashed black;
                "
              >
                <div style="display: flex">
                  <div
                    style="width: 50px; display: flex; flex-direction: column"
                  >
                    <!-- <img
                      style="
                        height: 50px;
                        border: none;
                        background: #f05138;
                        border-radius: 50%;
                        z-index: 10;
                      "
                      :src="item.schoolLogo"
                      alt=""
                    /> -->
                    <img
                      src="../../../assets/images/noopsycheFill/c.png"
                      alt=""
                      style="
                        width: 50px;
                        height: 57px;
                        border: 0;
                        margin-top: -22px;
                      "
                    />
                    <div
                      style="
                        margin-top: -35px;
                        text-align: center;
                        font-weight: bolder;
                        color: #ffffff;
                      "
                    >
                      {{ item.probability }}%
                    </div>
                  </div>
                  <div
                    style="
                      width: 1110px;
                      height: 100%;
                      padding-left: 20px;
                      position: relative;
                    "
                  >
                    <div style="font-size: 18px">
                      {{ item.schoolName
                      }}<span style="font-size: 14px"
                        >【{{ item.groupCode }}专业组】</span
                      >
                    </div>
                    <div style="margin-top: 10px; display: flex">
                      <span
                        style="
                          display: block;
                          min-width: 30px;
                          height: 25px;
                          text-align: center;
                          margin-right: 30px;
                        "
                        >{{ item.provinceName }}</span
                      >
                      <span
                        style="
                          display: block;
                          min-width: 30px;
                          height: 25px;
                          text-align: center;
                          margin-right: 30px;
                        "
                        >{{ item.regionName }}</span
                      ><span
                        style="
                          display: block;
                          min-width: 30px;
                          height: 25px;
                          text-align: center;
                          margin-right: 30px;
                        "
                        >{{ item.schoolType }}</span
                      ><span
                        style="
                          display: block;
                          min-width: 30px;
                          height: 25px;
                          text-align: center;
                          margin-right: 30px;
                        "
                        >{{ item.schoolNatureName }}</span
                      ><span
                        style="
                          display: block;
                          min-width: 30px;
                          height: 25px;
                          text-align: center;
                          margin-right: 30px;
                        "
                        >211</span
                      >
                    </div>
                    <div style="width: 100%">
                      <div style="margin-top: 10px; font-size: 16px">
                        <span style="margin-right: 30px">
                          {{ item.yearlyScores[0].yearName + 1 }}</span
                        >
                        招生计划&nbsp;<span style="margin-right: 30px">{{
                          item.sunPlanNbr ? item.sunPlanNbr : 0
                        }}</span
                        >&nbsp;&nbsp;院校代码&nbsp;<span
                          style="margin-right: 30px"
                          >{{ item.schoolCode }}</span
                        >&nbsp;&nbsp;{{
                          item.yearlyScores[0].yearName + 1
                        }}年选科&nbsp;<span style="margin-right: 30px"
                          >不限</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    background: #f5f5f5;
                    border-radius: 10px;
                    margin-top: 10px;
                  "
                >
                  <div v-for="item1 in item.majors" :key="item1.planId">
                    <div
                      style="
                        display: flex;
                        position: relative;
                        margin-left: -25px;
                        padding: 20px;
                      "
                    >
                      <div
                        style="width: 100px; text-align: center"
                        :style="{
                          color:
                            item.probabilityTypeCode == 'r001'
                              ? '#f05138'
                              : item.probabilityTypeCode == 'r002'
                              ? '#f59e49'
                              : item.probabilityTypeCode == 'r003'
                              ? '#2ccae1'
                              : item.probabilityTypeCode == 'r004'
                              ? '#28E197'
                              : '#576ae9',
                        }"
                      >
                        {{ item1.probability }}%
                      </div>
                      <div class="list-right">
                        <div style="font-size: 18px; margin-bottom: 10px">
                          {{ item1.schoolMajorName }}
                        </div>
                        <div style="color: #aeaeae; margin-bottom: 10px"></div>
                        <div style="margin-bottom: 10px">
                          {{ item1.yearlyScores[0].yearName + 1 }}招生计划{{
                            item1.planNums
                          }}
                          <span style="margin-right: 10px; margin-left: 60px"
                            >专业代码</span
                          >{{ item1.schoolMajorCode }}
                          <span style="margin-right: 10px; margin-left: 60px"
                            >学费/学制</span
                          >{{ item1.collegeFee }}/{{ item1.collegeYear }}
                        </div>
                        <div
                          v-if="drag == false"
                          style="
                            width: 150px;
                            height: 40px;
                            border: 1px solid #cc1d7a;
                            text-align: center;
                            line-height: 40px;
                            position: absolute;
                            right: 50px;
                            top: 50px;
                            color: #cc1d7a;
                            border-radius: 10px;
                          "
                          @click="deleteItem1(item, item1)"
                        >
                          删除
                        </div>
                        <div>
                          <div
                            style="display: flex; align-items: flex-end"
                            v-for="item2 in item1.yearlyScores"
                            :key="item2.yearName"
                          >
                            <div style="width: 100px">
                              {{ item2.yearName }}年
                            </div>
                            <span style="margin-right: 10px; margin-top: 10px"
                              >招生人数</span
                            >
                            <div style="width: 100px">
                              {{ item2.yearPlanNbr ? item2.yearPlanNbr : 0 }}名
                            </div>
                            <span
                              style="
                                margin-left: 60px;
                                margin-right: 10px;
                                margin-top: 10px;
                              "
                              >最低分</span
                            >
                            <div style="width: 100px">
                              {{
                                item2.yearAdmScore ? item2.yearAdmScore : "-"
                              }}分
                            </div>
                            <span
                              style="
                                margin-left: 60px;
                                margin-right: 10px;
                                margin-top: 10px;
                              "
                              >最低位次</span
                            >
                            <div style="width: 100px">
                              {{ item2.yearRank ? item2.yearRank : "-" }}名
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Draggable>
          </Container>
        </ul>
        <Nodata v-if="this.nodata"></Nodata>
      </div>
      <div class="footer">
        重要提示：<br />
        注：以上志愿表为模拟填报志愿表，正式填报请登录省考试院指定填报网站;
        申明：智能推荐使用当年招生计划和参考历年录取数据的志愿模拟填报平台，参考数据均来自省考试院，正式填报过程中如遇到数据错误，请参阅省考试院发布的相关招生计划书籍或拨打客服热线。
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryById,
  detailList,
  saveWishing,
  saveGroup,
  download,
  updateWishing,
  queryByWishing,
} from "../../../api/index";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Nodata from "@/components/NoData";
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  name: "detail",
  components: {
    Container,
    Draggable,
    Nodata,
  },
  data() {
    return {
      recordId: "",

      disable: false,
      wishName: "",
      comScore: "",
      firstMajor: "",
      dragElement: ".ul-li",
      checked: "data",
      // 展示列表
      dataList: [],
      dataList1: [],
      r001List: [],
      r002List: [],
      r003List: [],
      r004List: [],

      nodata: false,
      drag: true,

      titleYear: [],
      wishingedit: "",
      wishingeditname: "",
      wishModeCode: "",

      nodata: false,
      // data: [],
      groupList1: [],

      // 排序标志
      sort: 1,
      is985: false,
      isPublic: false,
    };
  },
  computed: {
    computeYear() {
      if (this.dataList[0] !== undefined) {
        this.dataList[0].yearlyScores.forEach((element) => {
          this.titleYear.push(element.yearName);
        });
      }
    },
    computeStyle() {
      return function name(yearName) {
        let arr = this.titleYear;
        let min = Math.min(...arr);
        let max = Math.max(...arr);
        return {
          textAlign: "center",
          position: "absolute",
          right: min == yearName ? "60px" : max == yearName ? "270px" : "160px",
        };
      };
    },
  },
  mounted() {
    this.getRouterQuery();
    this.getwishing();
    // this.getDetailList();
  },
  watch: {
    dataList: {
      handler(newVal, oldVal) {
        if (
          newVal.findIndex((find) => {
            return find.majors.length == 0;
          }) > -1
        ) {
          newVal.splice(
            newVal.findIndex((find) => {
              return find.majors.length == 0;
            }),
            1
          );
        }
      },
      deep: true,
    },
  },
  methods: {
    // 打印
    /*  print() {
      const elementHeight = document.getElementById('pdfContent').
      console.log(elementHeight,'qwer');
      

      this.$notify({
        title: "提示",
        type: "warning",
        message: "打印功能待开发！",
        showClose: false,
      });
    }, */
    async print() {
      const element = document.getElementById("pdfContent");
      console.log(element, "qwer");
      html2canvas(element, { scale: 2 }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "px",
          format: [element.scrollWidth, element.scrollHeight],
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("download.pdf");
      });
    },
    /*   async print() {
      const content = this.$refs.pdfContent;
      const canvas =  await html2canvas(content);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4',
      });
      const imgProps= pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('download.pdf');
    }, */
    deleteItem1(item, item1) {
      console.log(item, item1);
      let id = item.groupCode + item.schoolId;
      this.dataList = this.dataList.map((index) => {
        if (id == index.groupCode + index.schoolId) {
          console.log("专业组相同", index);
          index.majors = index.majors.filter((index_1) => {
            if (item1.recordId == index_1.recordId) {
              console.log("专业相同", index_1);
              this.takedataList();
              return false;
            }
            return true;
          });
        }
        return index;
      });
    },
    editwishing(id) {
      let data = {
        recordId: id,
        wishName: this.wishingeditname,
      };
      updateWishing(data).then((res) => {
        if (res.code == 200) {
          this.getwishing();
          this.wishingedit = "";
        }
      });
    },
    toEdit(data) {
      this.wishingedit = data;
      console.log(data);
    },
    deleteItem(index) {
      console.log(index, "index");
      if (index >= 0 && index < this.dataList.length) {
        this.dataList.splice(index, 1);
        // 重新分配 indexId
        this.dataList = this.dataList.map((item, idx) => ({
          ...item,
          indexId: idx,
        }));
        this.takedataList();
      }
    },
    downloadExcel() {
      download(this.recordId).then((res) => {
        console.log(res);
        let blob = new Blob([res.data], { type: "application/octet-stream" });
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        let headers = decodeURIComponent(
          res.headers["content-disposition"]
        ).split("=")[1];
        console.log("请求头", headers);
        link.download = headers;
        link.click();
        window.URL.revokeObjectURL(url);
      });
    },
    async saveOrModify() {
      if (!this.disable) {
        this.drag = !this.drag;
        if (this.drag) {
          this.dragElement = ".ul-li";
          let wishingId = this.recordId;
          if (this.wishModeCode == "wish_one") {
            let ids = [];
            this.dataList.forEach((item) => {
              ids.push(item.recordId);
            });
            console.log(ids);
            const data = {
              wishingId: wishingId,
              recordIds: ids,
            };
            await saveWishing(data);
          } else if (this.wishModeCode == "wish_team") {
            let groups = this.dataList.map((item) => ({
              groupCode: item.groupCode,
              recordIds: item.majors.map((item1) => item1.recordId),
            }));
            const data = {
              wishingId: wishingId,
              groups: groups,
            };
            await saveGroup(data);
          }
        } else {
          this.dragElement = ".ul-li-1";
        }
        this.disable = true;
        setTimeout(() => {
          this.disable = false;
        }, 1000);
      }
    },
    // 根据志愿概率分类
    takedataList(data) {
      console.log("23");
      let list = data;
      this.r001List = [];
      this.r002List = [];
      this.r003List = [];
      this.r004List = [];
      for (let index = 0; index < list.length; index++) {
        if (list[index].probabilityTypeCode == "r001") {
          this.r001List.push(list[index]);
        } else if (list[index].probabilityTypeCode == "r002") {
          this.r002List.push(list[index]);
        } else if (list[index].probabilityTypeCode == "r003") {
          this.r003List.push(list[index]);
        } else if (list[index].probabilityTypeCode == "r004") {
          this.r004List.push(list[index]);
        }
      }
    },
    getRouterQuery() {
      this.recordId = this.$route.query.data;
      // console.log('asd',this.recordId)
      // return this.$route.query;
    },
    onDrop(dropResult) {
      // 使用自定义的 applyDrag 函数
      let newItems = this.applyDrag(this.dataList, dropResult);

      // 重新分配 indexId
      newItems = newItems.map((item, index) => ({
        ...item,
        indexId: index,
      }));
      this.dataList = newItems;
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;
      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }
      console.log("result", result);
      this.dataList = result;
      return result;
    },
    getwishing() {
      // 247205952531464192
      queryById(this.recordId).then((res) => {
        this.wishName = res.data.wishName;
        this.comScore = res.data.comScore;
        this.firstMajor = res.data.firstMajor_dictText;
        this.wishModeCode = res.data.wishModeCode;
        this.getDetailList();
      });
    },
    // 获取志愿详情
    getDetailList() {
      if (this.wishModeCode == "wish_one") {
        detailList(this.recordId).then((res) => {
          console.log("res", res);
          this.dataList = res.data;
          this.dataList1 = res.data;
          for (let index = 0; index < this.dataList1.length; index++) {
            this.dataList[index].indexId = index;
          }
          this.takedataList(this.dataList);
        });
      } else if (this.wishModeCode == "wish_team") {
        queryByWishing(this.recordId).then((res) => {
          this.dataList = res.data;
          this.groupList1 = res.data;
          this.takedataList(this.dataList);
        });
      }
    },
    check(id) {
      console.log(id);

      this.checked = id;
      let listLength = this[`${id}List`].length;
      console.log(listLength);
      if (listLength == 0) {
        this.nodata = true;
      } else {
        this.nodata = false;
      }
    },
    top985() {
      this.is985 = !this.is985;
      this.sort = 1;
      this.isPublic = false;
      let isFirst = [];
      let noIsFirst = [];
      if (this.wishModeCode == "wish_one") {
        console.log("985,志愿表");
        if (this.is985 == true) {
          for (let index = 0; index < this.dataList.length; index++) {
            if (
              this.dataList[index].is985 == 1 ||
              this.dataList[index].is211 == 1
            ) {
              isFirst.push(this.dataList[index]);
            } else {
              noIsFirst.push(this.dataList[index]);
            }
          }
          this.dataList = isFirst.concat(noIsFirst);
        } else {
          this.dataList.sort((a, b) => {
            return a.timestamp - b.timestamp;
          });
        }
      } else if (this.wishModeCode == "wish_item") {
        console.log("985,志愿组");
        if (this.is985 == true) {
          for (let index = 0; index < this.dataList.length; index++) {
            if (
              this.dataList[index].is985 == 1 ||
              this.dataList[index].is211 == 1
            ) {
              isFirst.push(this.dataList[index]);
            } else {
              noIsFirst.push(this.dataList[index]);
            }
          }
          this.dataList = isFirst.concat(noIsFirst);
        } else {
          this.dataList.sort((a, b) => {
            return a.timestamp - b.timestamp;
          });
        }
      }
    },
    // 公办置顶
    topPublic() {
      this.sort = 1;
      this.is985 = false;
      this.isPublic = !this.isPublic;
      let isPublicArr = [];
      let noIsPublicArr = [];
      if (this.wishModeCode == "wish_one") {
        console.log("公办,志愿表");
        if (this.isPublic == true) {
          for (let i = 0; i < this.dataList.length; i++) {
            if (this.dataList[i].isPublic == 1) {
              isPublicArr.push(this.dataList[i]);
            } else {
              noIsPublicArr.push(this.dataList[i]);
            }
          }
          this.dataList = isPublicArr.concat(noIsPublicArr);
        } else {
          this.dataList.sort((a, b) => {
            return a.timestamp - b.timestamp;
          });
        }
      } else if (this.wishModeCode == "wish_item") {
        console.log("公办,志愿组");
        if (this.isPublic == true) {
          for (let index = 0; index < this.dataList.length; index++) {
            if (this.dataList[index].isPublic == 1) {
              isPublicArr.push(this.dataList[index]);
            } else {
              noIsPublicArr.push(this.dataList[index]);
            }
          }
        } else {
          this.dataList.sort((a, b) => {
            return a.timestamp - b.timestamp;
          });
        }
      }
    },
    // 分数排序
    sortChange() {
      this.is985 = false;
      this.isPublic = false;
      if (this.wishModeCode == "wish_one") {
        console.log("分数,志愿表");
        if (this.sort == 1) {
          this.sort = 2;
          this.dataList.sort((a, b) => {
            return (
              b.yearlyScores[0].yearAdmScore - a.yearlyScores[0].yearAdmScore
            );
          });
        } else if (this.sort == 2) {
          this.sort = 3;
          this.dataList.sort((a, b) => {
            return (
              a.yearlyScores[0].yearAdmScore - b.yearlyScores[0].yearAdmScore
            );
          });
        } else if (this.sort == 3) {
          this.sort = 1;
          this.dataList.sort((a, b) => {
            return a.timestamp - b.timestamp;
          });
        }
      } else if (this.wishModeCode == "wish_team") {
        if (this.sort == 1) {
          this.sort = 2;
          this.dataList.sort((a, b) => {
            return (
              b.yearlyScores[0].yearMinAdmScore -
              a.yearlyScores[0].yearMinAdmScore
            );
          });
        } else if (this.sort == 2) {
          this.sort = 3;
          this.dataList.sort((a, b) => {
            return (
              a.yearlyScores[0].yearMinAdmScore -
              b.yearlyScores[0].yearMinAdmScore
            );
          });
        } else if (this.sort == 3) {
          this.sort = 1;
          this.dataList.sort((a, b) => {
            return a.timestamp - b.timestamp;
          });
        }
        console.log("分数,志愿组");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
 // background: #f5f5f5;
  .centent {
    width: 1280px;
    min-height: 590px;
    // border: #ccc 1px solid;
    margin: 0px auto;
    margin-top: 39px;
    // overflow: hidden;
    .title {
      width: 1280px;
      height: auto;
      background: #ffffff;
      padding-bottom: 20px;
      .title-top {
        width: 1220px;
        // height: 50px;
        display: flex;
        padding: 20px 30px 0px 30px;
        justify-content: space-between;
        .left {
          font-size: 22px;
          display: flex;
          align-items: center;
        }
        .right {
          .button-data {
            background-color: #cc1d7a;
            color: #fff;
            border: 1px solid #cc1d7a;
          }
          .button-r001 {
            background-color: #f05138;
            color: #fff;
            border: #f05138 1px solid;
          }
          .button-r002 {
            background-color: #f59e49;
            border: 1px solid #f59e49;
            color: #fff;
          }
          .button-r003 {
            background-color: #2ccae1;
            border: 1px solid #2ccae1;
            color: #fff;
          }
          .button-r004 {
            background-color: #28e197;
            border: 1px solid #28e197;
            color: #fff;
          }
          .button-6 {
            background-color: #576ae9;
            border: 1px solid #576ae9;
            color: #fff;
          }
          .button-7 {
            background-color: #bbbbbb;
            border: 1px solid #bbbbbb;
            color: #fff;
          }
          .button-data-data {
            color: #cc1d7a;
            border: 1px solid #cc1d7a;
          }
          .button-r001-r001 {
            color: #f05138;
            border: #f05138 1px solid;
          }
          .button-r002-r002 {
            border: 1px solid #f59e49;
            color: #f59e49;
          }
          .button-r003-r003 {
            border: 1px solid #2ccae1;
            color: #2ccae1;
          }
          .button-r004-r004 {
            border: 1px solid #28e197;
            color: #28e197;
          }
          .button-6-6 {
            border: 1px solid #576ae9;
            color: #576ae9;
          }
          .button-7-7 {
            border: 1px solid #bbbbbb;
            color: #bbbbbb;
          }
        }
      }
      .title-main {
        width: 1220px;
        height: 30px;
        padding: 20px 30px 0px 30px;
        color: #b5b5b5;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        .main-btn {
          width: 66px;
          height: 32px;
          color: #cc1d7a;
          background: #ffd5c8;
          border: #cc1d7a;
        }
      }

      .sort-group {
        width: 100%;
        padding-left: 30px;
        padding-top: 10px;
      }

      .title-bottom {
        display: flex;
        width: 1220px;
        padding: 20px 30px 0px 30px;
        // border: #2397fe 1px solid;
        color: #aeaeae;
        font-size: 14px;
        // margin-bottom: 40px;
      }
    }
    .main {
      width: 1280px;
      max-height: 800px;
      background: #ffffff;
      padding-bottom: 20px;
      .ul-li {
        display: flex;
        align-items: center;
        height: 160px;
        width: 1220px;
        // border: #bbbbbb solid 1px;
        // 吸附的对齐方式，表示开头对其
        scroll-snap-align: start;
        // 表示要始终停止，不能跳过
        scroll-snap-stop: always;
        border-top: 1px dashed #707070;
      }
    }
    .footer {
      width: 1220px;
      height: 60px;
      padding: 20px 30px 20px 30px;
      margin-top: 20px;
      background: #ffffff;
      // overflow: hidden;
    }
  }
}
</style>